<template>
  <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
<path fill-rule="evenodd" clip-rule="evenodd" d="M16 5.5H4C3.72386 5.5 3.5 5.72386 3.5 6V14C3.5 14.2761 3.72386 14.5 4 14.5H16C16.2761 14.5 16.5 14.2761 16.5 14V6C16.5 5.72386 16.2761 5.5 16 5.5ZM4 4C2.89543 4 2 4.89543 2 6V14C2 15.1046 2.89543 16 4 16H16C17.1046 16 18 15.1046 18 14V6C18 4.89543 17.1046 4 16 4H4Z" fill="currentColor"/>
</svg>
</template>

<script>
export default {
  name: 'CornerRounded'
};
</script>
