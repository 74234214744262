import gpAbTestingTabIcon from './icons/ABTestingTab.vue';
import gpCornerPillIcon from './icons/CornerPill.vue';
import gpCornerRoundedIcon from './icons/CornerRounded.vue';
import gpCornerSingleIcon from './icons/CornerSingle.vue';
import gpCustomizeIcon from './icons/Customize.vue';
import gpDashIcon from './icons/Dash.vue';
import gpDotsIcon from './icons/Dots.vue';
import gpLineIcon from './icons/Line.vue';
import gpLinkIcon from './icons/Link.vue';
import gpLoadingIcon from './icons/Loading.vue';
import gpPaddingIcon from './icons/Padding.vue';
import gpShadowHardIcon from './icons/ShadowHard.vue';
import gpShadowLightIcon from './icons/ShadowLight.vue';
import gpShadowMediumIcon from './icons/ShadowMedium.vue';
import gpTextAlignJustifyIcon from './icons/TextAlignJustify.vue';
import gpUnlinkIcon from './icons/Unlink.vue';

export const icons = {
  'gp-ab-testing-tab': gpAbTestingTabIcon,
  'gp-corner-pill': gpCornerPillIcon,
  'gp-corner-rounded': gpCornerRoundedIcon,
  'gp-corner-single': gpCornerSingleIcon,
  'gp-customize': gpCustomizeIcon,
  'gp-dash': gpDashIcon,
  'gp-dots': gpDotsIcon,
  'gp-line': gpLineIcon,
  'gp-link': gpLinkIcon,
  'gp-loading': gpLoadingIcon,
  'gp-padding': gpPaddingIcon,
  'gp-shadow-hard': gpShadowHardIcon,
  'gp-shadow-light': gpShadowLightIcon,
  'gp-shadow-medium': gpShadowMediumIcon,
  'gp-text-align-justify': gpTextAlignJustifyIcon,
  'gp-unlink': gpUnlinkIcon,
};
