import type { Component, Control, PseudoStateSetting } from '../types/unit';
import type { ScreenType } from '../types/custom';
import type { ThemePageType } from '../types/graphql';
import { sentryCaptureException } from './sentry';

export const defaultSupportDevices: ScreenType[] = ['desktop', 'tablet', 'mobile']; //

export const isObject = (object: unknown) => {
  return object !== null && typeof object === 'object';
};

export function getCurrentValueFromControl({
  control,
  screenId,
}: {
  control: Control;
  state?: PseudoStateSetting;
  screenId: ScreenType;
}) {
  let valueDefault = null;
  // Update devices undefine to default
  if (control.devices && isObject(control.devices)) {
    // Merge default control by screen
    const indexScreen = defaultSupportDevices.findIndex((e) => e === screenId);
    const composeDeviceValue = defaultSupportDevices.slice(0, indexScreen + 1).reduce((curr, deviceId) => {
      const controlDevice = control.devices?.[deviceId];
      return {
        ...curr,
        ...(controlDevice !== undefined && controlDevice.default !== undefined && { ...controlDevice }),
      };
    }, {} as any);
    valueDefault = composeDeviceValue?.default;
  } else {
    valueDefault = control.default;
  }

  return valueDefault;
}

export const getCompoDefaultValueByDevice = (compoDefaultValue: any | undefined, screenId: ScreenType) => {
  const hasDevices =
    compoDefaultValue.desktop !== undefined ||
    compoDefaultValue.tablet !== undefined ||
    compoDefaultValue.mobile !== undefined;
  if (hasDevices) {
    const indexScreen = defaultSupportDevices.findIndex((e) => e === screenId);
    const composeDeviceValue = defaultSupportDevices.slice(0, indexScreen + 1).reduce((curr, deviceId) => {
      const controlDevice = compoDefaultValue?.[deviceId];
      return {
        ...curr,
        ...(controlDevice !== undefined && controlDevice.default !== undefined && { ...controlDevice }),
      };
    }, {} as any);
    return composeDeviceValue?.default;
  } else {
    return compoDefaultValue?.default;
  }
};

export const useControlSelectByCondition = (
  options: Record<string, any>[],
  sectionComponent?: Component | null,
  currentComponentUid?: string | null,
  editingPageType?: ThemePageType | 'THEME_SECTION',
) => {
  let isChangeOption = false;

  const newOptions = options.filter((option) => {
    const hideOnPage = option.hideOnPage;
    const flowTag = option.flowTag;
    const condition = option.condition;
    const hasFlowTagAndComponent = flowTag?.length && sectionComponent && currentComponentUid;
    if (hideOnPage?.length || hasFlowTagAndComponent) isChangeOption = true;
    if (condition) {
      if (hideOnPage?.length && hasFlowTagAndComponent) {
        const showOnThisPage = !hideOnPage.includes(editingPageType);
        const flowTagParent = findParentByUidAndByParentTag(sectionComponent, currentComponentUid, flowTag);
        if (condition == 'and' && showOnThisPage && flowTagParent) {
          return option;
        } else if (condition == 'or' && (showOnThisPage || flowTagParent)) {
          return option;
        }
      } else {
        return option;
      }
    } else if (hideOnPage?.length) {
      if (!hideOnPage.includes(editingPageType)) {
        return option;
      }
    } else if (hasFlowTagAndComponent) {
      if (findParentByUidAndByParentTag(sectionComponent, currentComponentUid, flowTag)) {
        return option;
      }
    } else {
      return option;
    }
  });

  return {
    newOptions,
    isChangeOption,
  };
};

export function findParentByUidAndByParentTag(
  jsonComponent: Component,
  uid: string,
  parentTag: string | string[],
): Component | null {
  const lastParent = getParentComponentByUid(jsonComponent, uid);
  if (lastParent && lastParent?.uid) {
    if (lastParent?.tag === parentTag || (Array.isArray(parentTag) && parentTag.includes(lastParent.tag))) {
      return lastParent;
    }
    return findParentByUidAndByParentTag(jsonComponent, lastParent?.uid, parentTag);
  }
  return null;
}

export function getParentComponentByUid(jsonComponent: Component, uid: string): Component | null {
  if (jsonComponent?.childrens?.length) {
    for (let i = 0; i < jsonComponent.childrens.length; i++) {
      const children = jsonComponent.childrens[i];
      if (children.uid == uid) {
        return jsonComponent;
      }
      const c = getParentComponentByUid(children, uid);
      if (c) {
        return c;
      }
    }
  }
  return null;
}

export function convertComponentToJSON(component: string): Component | undefined {
  try {
    return JSON.parse(component);
  } catch (e) {
    sentryCaptureException(
      'convertComponentToJSON',
      'Parse component error',
      {
        component,
      },
      {
        level: 'error',
      },
    );
  }
}

export function getComponentByUid(jsonComponent: Component, uid: string): Component | null {
  if (jsonComponent.uid == uid) {
    return jsonComponent;
  }
  if (jsonComponent.childrens?.length) {
    for (let i = 0; i < jsonComponent.childrens.length; i++) {
      const children = jsonComponent.childrens[i];
      const c = getComponentByUid(children, uid);
      if (c) {
        return c;
      }
    }
  }
  return null;
}
