<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue';
import type { GInputProps } from '../types/input';
import type { ButtonSize } from '@gem/uikit-v2';
import { GButton } from '@gem/uikit-v2';
import { BUTTON_STYLE_BY_SIZE, INPUT_STYLE_BY_TYPE } from '../const/input';
import { cn, useOutsideClick } from '@gem/common';
import { GIcon } from '@gem/icons';
import { useDebounceFn } from '@vueuse/core';

const props = withDefaults(defineProps<GInputProps>(), {
  inputStyle: 'normal',
  type: 'text',
  size: 'medium',
  disable: false,
  errorMessage: '',
});

const inputRef = ref<HTMLInputElement>();
const isFocus = ref<boolean>(false);

function focus() {
  if (inputRef.value) {
    inputRef.value.focus();
  }
}

function updateValue(value: string) {
  if (inputRef.value) {
    inputRef.value.value = value;
  }
}

function getValue() {
  if (inputRef.value) {
    return inputRef.value.value;
  }
}

defineExpose({
  focus,
  updateValue,
  getValue,
});

const stylesByTypeAndState = computed((): string => {
  const style = props.inputStyle
    ? INPUT_STYLE_BY_TYPE[props.inputStyle] || INPUT_STYLE_BY_TYPE.normal
    : INPUT_STYLE_BY_TYPE.normal;
  const mode = props.lightMode ? 'light' : 'dark';
  if (props.readonly) return cn(style.default[mode], style.hover[mode], props.active ? style.active[mode] : '');
  if (props.disable) return style.disabled[mode];
  if (props.errorMessage) return style.error[mode];
  return cn(style.default[mode], style.hover[mode], style.focus[mode]);
});

const styleBorder = computed(() => {
  const classNames = ['rounded-xl'];
  if (props.noBorder) {
    if (props.noBorder === 'left') classNames.push('rounded-l-none border-l-0');
    if (props.noBorder === 'right') classNames.push('rounded-r-none border-r-0');
    if (props.noBorder === 'top') classNames.push('rounded-t-none border-t-0');
    if (props.noBorder === 'bottom') classNames.push('rounded-b-none border-b-0');
    if (props.noBorder === 'all') classNames.push('rounded-none !border-0');
  }
  return cn(classNames);
});

const errorMessageColor = computed((): string => {
  if (props.errorMessage) {
    return props.lightMode ? 'text-red-400' : 'text-red-200';
  }
  return '';
});

const stylesBySize = computed((): string =>
  props.size ? BUTTON_STYLE_BY_SIZE[props.size] : BUTTON_STYLE_BY_SIZE.large,
);

const emit = defineEmits<{
  (e: 'change', value: string): void;
  (e: 'onChange', value: string): void;
  (e: 'blur', event?: any): void;
  (e: 'clickOutSide', value: string): void;
  (e: 'focus', event: any): void;
  (e: 'clear'): void;
}>();

const emitValue = (type: 'change' | 'onChange' | 'clickOutSide', value?: string) => {
  const valueEmit = removeEnter(value?.trim() || '');
  if (type === 'change') emit('change', valueEmit);
  else if (type === 'clickOutSide') emit('clickOutSide', valueEmit);
  else emit('onChange', valueEmit);
};

const removeEnter = (value?: string) => {
  return value?.toString()?.replace(/&nbsp;/g, ' ') || '';
};

const handleClear = () => {
  parseValue.value = '';
  !props.readonly && inputRef.value?.focus();
  emit('change', '');
  emit('clear');
};

const parseValue = ref(removeEnter(props.value));

const getStringLength = (str: string) => {
  return new TextEncoder().encode(str).length;
};

const maxLengthLabel = computed(() => {
  if (!props.maxLength) return '';
  const characterCount = parseValue.value?.length ? getStringLength(parseValue.value) : 0;
  return `${characterCount}/${props.maxLength}`;
});

const prefixIconSize = computed((): ButtonSize => {
  switch (props.size) {
    case 'large':
      return 'semi-medium';
    case 'medium':
      return 'normal';
    case 'small':
      return 'extra-small';
    default:
      return 'normal';
  }
});

const inputPaddingBySize = computed(() => {
  switch (props.size) {
    case 'large':
      return 'pl-42';
    case 'medium':
      return 'pl-40';
    case 'small':
      return 'pl-32';
    default:
      return 'pl-40';
  }
});

const handleInputBlur = () => {
  if (!isFocus.value) return;
  emitValue('clickOutSide', inputRef.value?.value);
  isFocus.value = false;
};

const handleOnChange = (e: any) => {
  parseValue.value = e.target.value;
  debouncedOnChange(e.target.value);
};

const debouncedOnChange = useDebounceFn((value?: string) => {
  emitValue('onChange', value);
}, 500);

const handleChange = (e: any) => {
  emitValue('change', e.target.value);
};

const handleFocus = (e: any) => {
  if (props.readonly) return;
  isFocus.value = true;
  e.target.select();
  emit('focus', e);
};

useOutsideClick(inputRef, handleInputBlur, { detectIframe: true, containSelectors: props.elmDisableBlurAction });

onMounted(() => {
  if (props.active && !props.readonly) focus();
});

watch(
  () => props.value,
  () => {
    parseValue.value = props.value;
  },
);
</script>

<template>
  <div class="relative w-full" :style="{ maxWidth: maxWidth }">
    <div class="relative flex w-full items-center">
      <div v-if="prefixIcon || prefixColor || prefixImage" class="absolute left-4 top-1/2 -translate-y-1/2">
        <div
          v-if="prefixColor || prefixImage"
          class="border-dark-50 h-[28px] w-[28px] rounded-[6px] border bg-contain bg-center bg-no-repeat"
          :style="{
            backgroundColor: prefixColor,
            backgroundImage: prefixImage
              ? `url('${prefixImage}')`
              : prefixColor?.includes('linear-gradient')
              ? prefixColor
              : 'none',
          }" />
        <GButton v-else type="tertiary" :size="prefixIconSize" :only-icon="prefixIcon" :no-select="true" />
      </div>
      <input
        ref="inputRef"
        class="w-full text-ellipsis rounded-xl outline-none transition-colors duration-200"
        data-test="editor-control-g-input-text"
        :placeholder="placeholder"
        :class="
          cn([
            stylesByTypeAndState,
            stylesBySize,
            styleBorder,
            maxLength ? (maxLength >= 100 ? 'pr-[58px]' : 'pr-[48px]') : '',
            prefixIcon || prefixColor || prefixImage ? inputPaddingBySize : '',
            clearButton && !disable && parseValue ? 'pr-[40px]' : '',
            readonly ? 'cursor-pointer select-none' : '',
            align ? `text-${align}` : '',
            type === 'number' ? 'appearance-none' : '',
            noBackground ? '!bg-transparent' : '',
          ])
        "
        :style="{
          paddingLeft: paddingLeft,
          paddingRight: paddingRight,
        }"
        :type="type"
        :value="parseValue"
        v-bind="$attrs"
        :disabled="disable"
        :readonly="readonly"
        :maxlength="maxLength"
        @blur="(e: any) => emit('blur', e)"
        @focus="handleFocus"
        @change="handleChange"
        @input="handleOnChange" />
      <div v-if="maxLength && !clearButton" class="text-text-light-100 text-12 leading-20 absolute right-8">
        {{ maxLengthLabel }}
      </div>
      <template v-if="clearButton && !disable">
        <div v-if="parseValue" class="absolute right-8">
          <GButton type="ghost" size="small" only-icon="polaris-x" :light-mode="lightMode" @click.stop="handleClear" />
        </div>
      </template>
    </div>
    <div
      v-if="errorMessage !== ''"
      class="text-12 font-regular mt-8 flex flex-row gap-8"
      :class="cn([errorMessageColor])">
      <GIcon name="polaris-alert-circle" :size="16" /><span>{{ errorMessage }}</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
input[type='number'] {
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
</style>
